import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import FlatButton from 'material-ui/FlatButton'
import wapplrcomponents from '../../../components'
import store from 'store'

import fms from '../empty.css'
import s from '../empty.css'

import Openpostmodal from '../Openpostmodal'
import opms from '../Openpostmodal/Openpostmodal.css'
import Postcontent from '../../posttypes/post/contents/content'
import helpers from '../../services/requests/helpers'

const Wapplrmenu = wapplrcomponents.menu

const Parent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

const Footer = createReactClass({
    getInitialState: function() {
        return {
            isMounted: false,
            showcookiesapprove: false,
            showcookiesclassname: ''
        }

    },
    componentDidMount: function() {

        const me = this.getMe()
        const state = this.getGlobalState()


        this.setState({
            showcookiesapprove: (me?.id || state?.isBot) ? false : !this.getApproveCookiesFromStore(),
            isMounted: true
        })
    },
    openCookiePost: function({ callback }) {

        const { curlang = {}, cookiepostid } = this.props
        const modal = this.refElements['openpostmodal']
        const props = this.props

        const {
            closetext = 'Close'
        } = curlang

        if (modal && modal.open) {
            modal.open({
                title: '',
                text: '',
                content: Postcontent,
                contentprops: async function({ callback }) {

                    const postQuery = helpers.post()

                    const postRequest = {
                        method: 'post',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: function({ id }) {
                            const reqName = 'current' + 'post'
                            return JSON.stringify({
                                query: `{
                                    ${reqName}(id:"${id}") {
                                        ${postQuery}
                                    }
                                }`
                            })
                        },
                        credentials: 'include'
                    }

                    const pRbody = postRequest.body({ id: cookiepostid })
                    const fresp = await fetch('/graphql', { ...postRequest, body: pRbody })
                    let response = (fresp && fresp.json) ? await fresp.json() : null
                    if (response.data) response = response.data

                    const r = {
                        ...props,
                        Parent: Parent,
                        response: response
                    }

                    if (callback) callback({ response: r })
                    return r

                },
                submittext: closetext,
                canceltext: null,
                action: function({ dialogClose }) {
                    dialogClose()
                    callback()
                }
            })
        } else {
            callback()
        }
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getApproveCookiesFromStore: function() {
        const { localstorename = 'wapplr' } = this.props
        const { state } = this.getHelpers()
        const initialStateTime = (state && state.runtime && state.runtime.initialNow) ? state.runtime.initialNow : 0
        const storedname = localstorename + '_wapplrcookie'

        const storeddata = store.get(storedname)

        const wait = 60000 * 30
        const time = new Date().getTime()
        const lasttime = (storeddata && storeddata.lasttime) ? storeddata.lasttime : (time - wait)
        const storedapprove = (storeddata && storeddata[initialStateTime]) ? storeddata[initialStateTime] : false

        const approved = (storedapprove) ? storedapprove : time < (lasttime + wait)

        return (storeddata) ? approved : null
    },
    setApproveCookieState: function() {
        this.setState({
            showcookiesapprove: false
        })
    },
    setApproveCookies: function() {

        const { localstorename = 'wapplr' } = this.props
        const { state } = this.getHelpers()
        const initialStateTime = (state && state.runtime && state.runtime.initialNow) ? state.runtime.initialNow : 0
        const storedname = localstorename + '_wapplrcookie'

        const time = new Date().getTime()
        store.set(storedname, { [initialStateTime]: true, lasttime: time })

        const setApproveCookieState = this.setApproveCookieState
        this.setState({
            showcookiesclassname: 'hiddencookieline'
        })
        setTimeout(function() {
            setApproveCookieState()
        }, 500)
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {

        const { type } = d

        if (type === 'approvecookie') {
            this.setApproveCookies()
        }
        if (type === 'opencookiepost') {
            this.openCookiePost({
                callback: function() {
                }
            })
        }
        e.preventDefault()
    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    render: function() {
        const { style, menus, menustyle = fms, searchData, history, curlang = {}, footercopyright = 'FlatChooser 2022. ©', } = this.props

        const {
            approvecookietext = 'We use cookies and other technologies to ensure everyone who uses our services has the best possible experience.',
            cookieapprovetext = 'I got it',
            moretext = 'more >>'
        } = curlang

        const me = this.getMe()
        const state = this.state
        const touchEnd = this.touchEnd
        const setRef = this.setRef

        const opminput = {
            ...this.props,
            setRef: setRef,
            style: opms
        }

        return (
            <div>
                {(state.showcookiesapprove) ?
                    <div
                        className={(state.showcookiesclassname) ? style.approvecookie + ' ' + style[state.showcookiesclassname] : style.approvecookie}>
                        <div className={style.approvecookieleft}>
                            {approvecookietext}
                            <span
                                className={style.approvecookiemore}
                                onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'opencookiepost' })
                            }}>
                                {moretext}
                            </span>
                        </div>
                        <div className={style.approvecookieright}>
                            <FlatButton label={cookieapprovetext}
                                        onTouchTap={function(e) {
                                            touchEnd(e, 'click', 'click', { type: 'approvecookie' })
                                        }}
                                        onTouchStart={function() {
                                        }}
                            />
                        </div>
                        <Openpostmodal {...opminput} />
                    </div>
                    : null
                }
                <div className={style.footer}>
                    <div>
                        <Wapplrmenu
                            style={menustyle}
                            menu={menus['footer']}
                            history={history}
                            searchData={searchData}
                            type='menu'
                            breakpoint={1}
                            sendprops={{ user: me }}
                        />

                    </div>
                    <div className={style.copyright}>
                        {footercopyright}
                    </div>
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Footer {...this.props} ref={(setRef) ? function(e) {
                setRef('Footer', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
